(function ($, Drupal, site) {
  Drupal.behaviors.sitewideBannerV2 = {
    // Resets padding at slider init.
    setPageWrapperPadding: function (context, headerHeight) {
      var $pageWrapper = $('.page-wrapper', context);
      var currentPadding = $pageWrapper.css('padding-top');
      var $pageHeader = $('.page-header', context);
      var currentHeaderHeight = headerHeight || $pageHeader.height();
      var pageWrapperHeightStyle = currentHeaderHeight + 'px';

      // due to text wrap, the header
      // padding sometimes needs an update
      if (pageWrapperHeightStyle !== currentPadding) {
        $pageWrapper.css('padding-top', pageWrapperHeightStyle);
      }
    },
    attach: function (context) {
      var $siteBanner = $();
      var $sliderContent = $();
      var $guestContent = $();
      var $loyaltyContent = $();
      var $nonLoyaltyContent = $();
      var $close = $();
      var privateBrowsing = false;
      var isSignedUser = 0;
      var animationTime = 0;
      var userInfo = {};
      var mapObj = {};
      var self = this;
      var slideIndex = 1;
      var speed = 5000;
      var $slider = $();
      var enableCarousel = 0;
      var slickProperites = {
        infinite: true,
        dots: false,
        autoplay: true,
        autoplaySpeed: 5000,
        fade: true,
        cssEase: 'ease-in-out',
        touchThreshold: 100,
        speed: 30
      };
      var $sliderContentSlide = $();
      var $sliderBoutiqueTimerSlider = $();
      var enableTimerInSiteBanner = 0;

      // disable for checkout
      if ($('body').hasClass('checkout')) {
        return;
      }

      // Basic tests to make sure we're not in private browsing like on iOS
      try {
        sessionStorage.setItem('sessionTestVar', 1);
        sessionStorage.removeItem('sessionTestVar');
      } catch (err) {
        privateBrowsing = true;
      }
      if (privateBrowsing) {
        return;
      }

      // Starting point
      $siteBanner = $('.js-site-banner-formatter', context);
      $sliderContent = $('.js-site-banner-formatter-slider', $siteBanner);
      $guestContent = $('.js-guest', $sliderContent);
      $loyaltyContent = $('.js-loyalty', $sliderContent);
      $nonLoyaltyContent = $('.js-non-loyalty', $sliderContent);
      $close = $('.js-close-site-banner-formatter', $siteBanner);
      $slider = $('.js-site-banner-formatter-slides', $sliderContent);
      $sliderBoutiqueTimerSlider = $('.js-boutique-x5-countdown-clock-site-banner', $slider);
      enableCarousel = $sliderContent.data('bannerCarousel');
      enableTimerInSiteBanner = $sliderBoutiqueTimerSlider.data('enableCounterBanner');

      isSignedUser = site.userInfoCookie.getValue('signed_in') - 0;
      animationTime = 400;
      Drupal.ELB.loadPersistenUserCookie();
      userInfo = Drupal.ELB.getJSONCookie('persistent_user_cookie');
      if (userInfo) {
        userInfo.first_name = (userInfo.first_name || '').replace(/\+/g, ' ');
        userInfo.loyalty_level_name = (userInfo.loyalty_level_name || '').replace(/\+/g, ' ');
        mapObj = {
          '::user::': truncate(userInfo.first_name, 17),
          '::current_level::': userInfo.loyalty_level_name,
          '::points::': userInfo.points
        };
        $loyaltyContent.add($nonLoyaltyContent).html(function () {
          return $(this)
            .html()
            .replace(/::user::|::current_level::|::points::/gi, function (matched) {
              return mapObj[matched];
            });
        });
        if (isSignedUser && parseInt(userInfo.is_loyalty_member, 10)) {
          $nonLoyaltyContent.remove();
          $guestContent.remove();
        } else if (isSignedUser) {
          $loyaltyContent.remove();
          $guestContent.remove();
        } else {
          $loyaltyContent.remove();
          $nonLoyaltyContent.remove();
        }
      }

      $sliderContentSlide = $('.js-site-banner-slide', $sliderContent);

      // Should we show our banner?
      if (!sessionStorage.getItem('banner_viewed') && !$('body').hasClass('site-banner__is-open')) {
        $('body').addClass('site-banner__is-open');
        maxHeightSlides();
        self.setPageWrapperPadding(context);
        showSlides(slideIndex);
        $sliderContent.removeClass('not-initialized');
      } else {
        $siteBanner.remove();
        $('body').addClass('site-banner__is-hidden');
      }

      $(window).on(
        'resize',
        _.debounce(function () {
          if ($('body').hasClass('site-banner__is-open')) {
            maxHeightSlides();
            self.setPageWrapperPadding(context);
          }
        }, 250)
      );

      function maxHeightSlides() {
        var maxHeight;

        maxHeight = Math.max.apply(
          null,
          $sliderContent
            .find('.js-site-banner-slide')
            .map(function () {
              return $(this).innerHeight();
            })
            .get()
        );
        $sliderContent.css('height', maxHeight);
        $sliderContentSlide.css('height', maxHeight);
        $siteBanner.css('visibility', 'visible');

        if (enableTimerInSiteBanner === 1) {
          $sliderBoutiqueTimerSlider.css('height', maxHeight);
        }
      }

      function showSlides(n) {
        if (n === undefined) {
          n = ++slideIndex;
        }
        if (n > $sliderContentSlide.length) {
          slideIndex = 1;
        }
        if (n < 1) {
          slideIndex = $sliderContentSlide.length;
        }
        $sliderContentSlide.removeClass('item-active');
        $sliderContentSlide.eq(slideIndex - 1).addClass('item-active');
        setTimeout(showSlides, speed);
      }

      function truncate(firstName, limit) {
        return firstName.length < limit ? firstName : firstName.substring(0, limit) + '...';
      }

      // Close the banner for the session
      $close.once().on('click', function () {
        sessionStorage.setItem('banner_viewed', 1);
        $siteBanner.slideUp(animationTime, function () {
          $('body').removeClass('site-banner__is-open').addClass('site-banner__is-hidden');
          $(document).trigger('siteBanner.close');
          $siteBanner.remove();

          var $pageHeader = $('.page-header', context);
          var headerHeight = $pageHeader.height();

          self.setPageWrapperPadding(context, headerHeight);
        });
      });

      if (enableCarousel === 1 && !$slider.hasClass('slick-initialized')) {
        $slider.slick(slickProperites);
      }
    }
  };
})(jQuery, Drupal, window.site || {});
